import React, { useState,  useEffect } from "react";
import { Autocomplete, Box, Typography, TextField } from "@mui/material";
import endpoints from "../../../constants/endpoints";
import useDebounce from "../../../helpers/useDebounce";
import { updateHorseDataByHeading } from "../../../helpers/util";
const sxChildLabel = {
  color: "black",
  marginBottom: "4px",
  fontSize: "12px",
  marginTop: "8px",
};
const subheading = {
  color: "black",
  fontWeight: "bold",
  marginBottom: "4px",
  fontSize: "14px",
};
const UelnHeading = {
  color: "red",
  fontWeight: "bold",
  marginBottom: "4px",
  fontSize: "14px",
};
const sxTextField = {
  borderRadius: "10px !important",
  "& .MuiInputBase-root": {
    padding: "0px",
    fontSize: "13px",
  },
  "& .MuiInputLabel-root": {
    fontSize: "12px",
    fontWeight: "bold",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "10px !important",
  },
  "& .MuiAutocomplete-popupIndicator": {
    display: "none",
  },
  "& .MuiOutlinedInput-root": {
    paddingRight: "0px",
  },
};
const sxHorseCard = {
  border: "1px solid rgb(215, 222, 236)",
  boxSizing: "border-box",
  borderRadius: "8px",
  padding: "10px 8px",
  textAlign: "start",
  minWidth: "165px",
  margin: "8px auto",
  backgroundColor: "white",
};
const HorseCard = ({
  horse,
  childLabel,
  currentLevel,
  setHorseData,
  horseData,
}) => {
  const [searchValue, setSearchValue] = useState({});
  const [horseSuggestions, setHorseSuggestions] = useState([]);
  const [horseValue, setHorseValue] = useState(horse?.name || "");
  const [focusedKey, setFocusedKey] = useState(null); // Track the focused key
  const debouncedSearchValue = useDebounce(
    focusedKey ? searchValue[focusedKey] || "" : "",
    500
  );

  // Initialize searchValue from horse.name
  useEffect(() => {
      setSearchValue((prev) => ({
        ...prev,
        [horse.heading]: horse.name, // Update only the current key's value
      }));
  }, [horse]);

  useEffect(() => {
      setHorseValue(horse.name);
  }, [horse]);

  const fetchHorses = async (value) => {
    if (value.length >= 3) {
      try {
        const response = await fetch(
          `${endpoints.HORSES.PEDIGREE_HORSE_SEARCH}?term=${
            searchValue[horse.heading]
          }`
        );
        const data = await response.json();

        if (data.success) {
          const suggestions = data.data.map((horse) => ({
            id: horse.id,
            name: horse.name,
          }));
          setHorseSuggestions(suggestions);
        } else {
          setHorseSuggestions([]);
        }
      } catch (error) {
        console.error("Error fetching horse names:", error);
        setHorseSuggestions([]);
      }
    } else {
      setHorseSuggestions([]);
    }
  };

  useEffect(() => {
    if (currentLevel === 0) return;
    if (focusedKey === horse.heading && debouncedSearchValue) {
      fetchHorses(debouncedSearchValue); // Trigger API only for the focused input
    }
  }, [debouncedSearchValue, focusedKey, horse.heading]);

  const handleHorseSelect = async (event, newValue) => {
    if (!newValue) return;
    setSearchValue((prev) => ({
      ...prev,
      [horse.heading]: newValue.name, // Update only the current key's value
    }));
    if (currentLevel === 3) {
      return;
    }
    // Calling after horse is selected
    const fetchPedigree = async () => {
      try {
        const query = newValue.id ? `?id=${newValue.id}&term=${newValue.name}&currentLevel=${currentLevel}` : `?term=${newValue.name}&currentLevel=${currentLevel}`;
        const response = await fetch(
          `${endpoints.HORSES.GET_PEDIGREE_BY_LEVEL_SEARCH}${query}`
        );
        const data = await response.json();
        if (data.success) {
          const responseData = data.data
          const updatedData = updateHorseDataByHeading(
            horseData,
            horse.heading,
            responseData
          );
          setHorseData(updatedData);
        }
      } catch (error) {
        console.error("Error fetching pedigree data:", error);
      }
    };

    fetchPedigree();
  };

  const handleInputChange = (event, newInputValue) => {
    setSearchValue((prev) => ({
      ...prev,
      [horse.heading]: newInputValue, // Update only the current key's value
    }));

    // Update the horseData state with the new name based on the horse.heading
    setHorseData((prevHorseData) => {
      // Create a recursive function to update name based on heading while keeping other data intact
      const updateNameBasedOnHeading = (data, heading, newName) => {
        if (data.heading === heading) {
          return { ...data, name: newName }; // Update the name for matching heading
        }
        // If there are children or nested data, apply recursively
        for (const key in data) {
          if (data[key] && typeof data[key] === "object") {
            data[key] = updateNameBasedOnHeading(data[key], heading, newName); // Recurse
          }
        }
        return data;
      };

      // Update the horseData with the new name based on the current heading and new input
      return updateNameBasedOnHeading(
        { ...prevHorseData },
        horse.heading,
        newInputValue
      );
    });
  };

  const handleFocus = () => {
    setFocusedKey(horse.heading); // Set the current key as focused
  };

  return (
    <Box sx={sxHorseCard}>
      <Typography
        style={{
          color: currentLevel === 0 ? "red" : "black",
          fontWeight: "bold",
          marginBottom: "4px",
          fontSize: "12px",
        }}
      >
        {horse.heading}
      </Typography>

      {currentLevel === 0 && (
        <Typography sx={subheading}>{horse.name}</Typography>
      )}

      {childLabel && (
        <Autocomplete
          key={horse.heading}
          value={searchValue[horse.heading] || horseValue}
          onFocus={handleFocus} // Track focus
          onInputChange={handleInputChange}
          options={horseSuggestions}
          onChange={handleHorseSelect}
          getOptionLabel={(option) => option.name || option}
          renderInput={(params) => <TextField {...params} sx={sxTextField} />}
          renderOption={(props, option) => (
            <li {...props} key={option.id || option}>
              {option.name}
            </li>
          )}
        />
      )}

      {childLabel && (
        <Typography sx={sxChildLabel}>
          Breeding Book number & Section
        </Typography>
      )}

      <Typography sx={subheading}>{horse.subheading}</Typography>

      <Typography sx={UelnHeading}>{horse.UelnHeading}</Typography>

      <Typography fontSize="14px" fontWeight={400}>
        {horse.sectionName}
      </Typography>
    </Box>
  );
};

export default HorseCard;
