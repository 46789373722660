import { parse, isDate } from "date-fns";
const moment = require("moment");

var _ = require("lodash");

export const isSignin = () => {
  const token = sessionStorage.getItem("@App:token");
  return Boolean(token);
};
export const getToken = () => {
  return sessionStorage.getItem("@App:token");
};

export const setToken = (token) => {
  return sessionStorage.setItem("@App:token", token);
};
export const Logout = () => {
  sessionStorage.removeItem("@App:token");
  sessionStorage.removeItem("@App:user");
};

export const findResult = (results, name) => {
  var result = _.find(results, function (obj) {
    return obj.types[0] === name && obj.types[1] === "political";
  });
  return result ? result.long_name : null;
};
export const getUserDetails = () => {
  const storagedUser = sessionStorage.getItem("@App:user");
  const storagedUserObj = JSON.parse(storagedUser);
  return JSON.parse(storagedUserObj.userData);
};

export const calculate_age = (dob) => {
  var today = new Date();
  var birthDate = new Date(dob);
  var age_now = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age_now--;
  }
  return age_now;
};
export const calculate_age_by_year = (dob) => {
  var today = new Date().getFullYear();
  var birthDate = new Date(dob).getFullYear();
  var age_now = today - birthDate;
  return age_now;
};
export function parseDateString(value, originalValue) {
  const parsedDate = isDate(originalValue)
    ? originalValue
    : parse(originalValue, "dd-MM-yyyy", new Date());

  return parsedDate;
}
export const dateFormat = (date) => {
  var dateFormat = moment(date).format("DD/MM/yyyy");
  return dateFormat;
};
export const dateTimeFormat = (date) => {
  var dateFormat = moment(date).format("MMMM D, YYYY h:mm ");

  return dateFormat;
};
export const dateTimeAMPMFormat = (date) => {
  var dateFormat = moment(date).format("MMMM D, YYYY h:mm a  ");

  return dateFormat;
};
export const dateMonthFormat = (date) => {
  var dateFormat = moment(date).format("MMMM D, YYYY  ");

  return dateFormat;
};
export const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.pdf)$/i;
export const allowedProfileExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

export const horsePedigreeTreeData = (horseName, horseId) => {
  return {
    id: 0,
    heading: "Horse Name",
    name: horseName,
    horseId: horseId,
    currentLevel: 0,
    UelnHeading: "UELN",
    sectionName: "TROTTEUR FRANCAIS Class",
    sire: {
      heading: "11.1.Sire",
      name: "",
      horseId: 0,
      UelnHeading: "UELN",
      sectionName: "Main Section",
      currentLevel: 1,
      key: "sire",
      grandSire: {
        heading: "11.1.1.Paternal Grandsire",
        name: "",
        horseId: 0,
        UelnHeading: "",
        sectionName: "",
        currentLevel: 2,
        key: "grandSire",
        greatGrandSire: {
          heading: "11.1.1.1.Paternal Grand-Grandsire",
          name: "",
          horseId: 0,
          UelnHeading: "",
          sectionName: "",
          currentLevel: 3,
          key: "greatGrandSire",
        },
        greatGrandDame: {
          heading: "11.1.1.2.Paternal Grand-Granddam",
          name: "",
          horseId: 0,
          UelnHeading: "",
          sectionName: "",
          currentLevel: 3,
          key: "greatGrandDame",
        },
      },
      grandDame: {
        heading: "11.1.2.Paternal Granddam",
        name: "",
        horseId: 0,
        UelnHeading: "",
        sectionName: "",
        currentLevel: 2,
        key: "grandDame",
        greatGrandSire: {
          heading: "11.1.2.1.Paternal Grand-Grandsire",
          name: "",
          horseId: 0,
          UelnHeading: "",
          sectionName: "",
          currentLevel: 3,
          key: "greatGrandSire",
        },
        greatGrandDame: {
          heading: "11.1.2.2.Paternal Grand-Granddam",
          name: "",
          horseId: 0,
          UelnHeading: "",
          sectionName: "",
          currentLevel: 3,
          key: "greatGrandDame",
        },
      },
    },
    dame: {
      heading: "11.2.Dam",
      name: "",
      horseId: 0,
      UelnHeading: "UELN",
      sectionName: "Main Section",
      currentLevel: 1,
      key: "dame",
      grandSire: {
        heading: "11.2.1.Maternal Grandsire",
        name: "",
        horseId: 0,
        UelnHeading: "",
        sectionName: "",
        currentLevel: 2,
        key: "grandSire",
        greatGrandSire: {
          heading: "11.2.1.1.Maternal Grand-Grandsire",
          name: "",
          horseId: 0,
          UelnHeading: "",
          sectionName: "",
          currentLevel: 3,
          key: "greatGrandSire",
        },
        greatGrandDame: {
          heading: "11.2.1.2.Maternal Grand-Granddam",
          name: "",
          horseId: 0,
          UelnHeading: "",
          sectionName: "",
          currentLevel: 3,
          key: "greatGrandDame",
        },
      },
      grandDame: {
        heading: "11.2.2.Maternal Granddam",
        name: "",
        horseId: 0,
        UelnHeading: "",
        sectionName: "",
        currentLevel: 2,
        key: "grandDame",
        greatGrandSire: {
          heading: "11.2.2.1.Maternal Grand-Grandsire",
          name: "",
          horseId: 0,
          UelnHeading: "",
          sectionName: "",
          currentLevel: 3,
          key: "greatGrandSire",
        },
        greatGrandDame: {
          heading: "11.2.2.2.Maternal Grand-Granddam",
          name: "",
          horseId: 0,
          UelnHeading: "",
          sectionName: "",
          currentLevel: 3,
          key: "greatGrandDame",
        },
      },
    },
  };
};

export const updateHorseDataWithNames = (apiData, horseData) => {
  const updatedHorseData = { ...horseData };

  for (const key in updatedHorseData) {
    if (updatedHorseData[key] && typeof updatedHorseData[key] === "object") {
      // Recursively update nested objects
      updatedHorseData[key] = updateHorseDataWithNames(
        apiData[key] || {},
        updatedHorseData[key]
      );
    } else if (key === "name" && apiData[key]) {
      updatedHorseData.name = apiData[key];
    }
  }

  if (apiData.greatGrandDame) {
    updatedHorseData.greatGrandDame = {
      ...updatedHorseData.greatGrandDame,
      name: apiData.greatGrandDame,
    };
  }

  if (apiData.greatGrandSire) {
    updatedHorseData.greatGrandSire = {
      ...updatedHorseData.greatGrandSire,
      name: apiData.greatGrandSire,
    };
  }

  return updatedHorseData;
};

export const findHorseSection = (data, heading, currentLevel) => {
  // Base condition to match the current object
  if (data.heading === heading && data.currentLevel === currentLevel) {
    return data;
  }

  // Recursive search through "sire" and "dame" if they exist
  if (data.sire) {
    const sireResult = findHorseSection(data.sire, heading, currentLevel);
    if (sireResult) return sireResult;
  }

  if (data.dame) {
    const dameResult = findHorseSection(data.dame, heading, currentLevel);
    if (dameResult) return dameResult;
  }

  // Recursive search through "grandSire" and "grandDame" if they exist
  if (data.grandSire) {
    const grandSireResult = findHorseSection(
      data.grandSire,
      heading,
      currentLevel
    );
    if (grandSireResult) return grandSireResult;
  }

  if (data.grandDame) {
    const grandDameResult = findHorseSection(
      data.grandDame,
      heading,
      currentLevel
    );
    if (grandDameResult) return grandDameResult;
  }

  // Recursive search through "greatGrandSire" and "greatGrandDame" if they exist
  if (data.greatGrandSire) {
    const greatGrandSireResult = findHorseSection(
      data.greatGrandSire,
      heading,
      currentLevel
    );
    if (greatGrandSireResult) return greatGrandSireResult;
  }

  if (data.greatGrandDame) {
    const greatGrandDameResult = findHorseSection(
      data.greatGrandDame,
      heading,
      currentLevel
    );
    if (greatGrandDameResult) return greatGrandDameResult;
  }

  // Return null if no match is found
  return null;
};

export const updateHorseDataByHeading = (horseData, heading, responseData) => {
  let updatedHorseData = { ...horseData };
  switch (heading) {
    case "11.1.Sire":
      updatedHorseData.sire = {
        ...horseData.sire,
        name: responseData?.name || "",
        horseId: responseData.horseId || 0,
        grandSire: {
          ...horseData.sire.grandSire,
          name: responseData?.sire?.name || "",
          horseId: responseData?.sire?.horseId || 0,
          greatGrandSire: {
            ...horseData.sire.grandSire.greatGrandSire,
            name: responseData?.sire?.grandSire?.name || "",
            horseId: responseData?.sire?.grandSire?.horseId || 0,
          },
          greatGrandDame: {
            ...horseData.sire.grandSire.greatGrandDame,
            name: responseData?.sire?.grandDame?.name || "",
            horseId: responseData?.sire?.grandDame?.horseId || 0,
          },
        },
        grandDame: {
          ...horseData.sire.grandDame,
          name: responseData?.dame?.name || "",
          horseId: responseData?.dame?.horseId || 0,
          greatGrandSire: {
            ...horseData.sire.grandDame.greatGrandSire,
            name: responseData?.dame?.grandSire?.name || "",
            horseId: responseData?.dame?.grandSire?.horseId || 0,
          },
          greatGrandDame: {
            ...horseData.sire.grandDame.greatGrandDame,
            name: responseData?.dame?.grandDame?.name || "",
            horseId: responseData?.dame?.grandDame?.horseId || 0,
          },
        },
      };
      break;

    case "11.1.1.Paternal Grandsire":
      updatedHorseData.sire.grandSire = {
        ...horseData.sire.grandSire,
        name: responseData?.name || "",
        horseId: responseData.horseId || 0,
        greatGrandSire: {
          ...horseData.sire.grandSire.greatGrandSire,
          name: responseData?.sire?.name || "",
          horseId: responseData?.sire.horseId || 0,
        },
        greatGrandDame: {
          ...horseData.sire.grandSire.greatGrandDame,
          name: responseData?.dame?.name || "",
          horseId: responseData?.dame.horseId || 0,
        },
      };
      break;

    case "11.1.2.Paternal Granddam":
      updatedHorseData.sire.grandDame = {
        ...horseData.sire.grandDame,
        name: responseData?.name || "",
        horseId: responseData.horseId || 0,
        greatGrandSire: {
          ...horseData.sire.grandDame.greatGrandSire,
          name: responseData?.sire?.name || "",
          horseId: responseData?.sire.horseId || 0,
        },
        greatGrandDame: {
          ...horseData.sire.grandDame.greatGrandDame,
          name: responseData?.dame?.name || "",
          horseId: responseData?.dame.horseId || 0,
        },
      };
      break;

    case "11.2.Dam":
      updatedHorseData.dame = {
        ...horseData.dame,
        name: responseData?.name || "",
        horseId: responseData.horseId || 0,
        grandSire: {
          ...horseData.dame.grandSire,
          name: responseData?.sire?.name ? responseData?.sire?.name : "",
          horseId: responseData?.sire.horseId || 0,
          greatGrandSire: {
            ...horseData.dame.grandSire.greatGrandSire,
            name: responseData?.sire?.grandSire?.name
              ? responseData?.sire?.grandSire?.name
              : "",
            horseId: responseData?.sire?.grandSire?.horseId || 0,
          },
          greatGrandDame: {
            ...horseData.dame.grandSire.greatGrandDame,
            name: responseData?.sire?.grandDame?.name
              ? responseData?.sire?.grandDame?.name
              : "",
            horseId: responseData?.sire?.grandDame?.horseId || 0,
          },
        },
        grandDame: {
          ...horseData.dame.grandDame,
          name: responseData?.dame?.name || "",
          horseId: responseData?.dame.horseId || 0,
          greatGrandSire: {
            ...horseData.dame.grandDame.greatGrandSire,
            name: responseData?.dame?.grandSire?.name || "",
            horseId: responseData?.dame?.grandSire?.horseId || 0,
          },
          greatGrandDame: {
            ...horseData.dame.grandDame.greatGrandDame,
            name: responseData?.dame?.grandDame?.name || "",
            horseId: responseData?.dame?.grandDame?.horseId || 0,
          },
        },
      };
      break;

    case "11.2.1.Maternal Grandsire":
      updatedHorseData.dame.grandSire = {
        ...horseData.dame.grandSire,
        name: responseData?.name || "",
        horseId: responseData.horseId || 0,
        greatGrandSire: {
          ...horseData.dame.grandSire.greatGrandSire,
          name: responseData?.sire?.name || "",
          horseId: responseData?.sire.horseId || 0,
        },
        greatGrandDame: {
          ...horseData.dame.grandSire.greatGrandDame,
          name: responseData?.dame?.name || "",
          horseId: responseData?.dame.horseId || 0,
        },
      };
      break;

    case "11.2.2.Maternal Granddam":
      updatedHorseData.dame.grandDame = {
        ...horseData.dame.grandDame,
        name: responseData?.name || "",
        horseId: responseData.horseId || 0,
        greatGrandSire: {
          ...horseData.dame.grandDame.greatGrandSire,
          name: responseData?.sire?.name || "",
          horseId: responseData?.sire.horseId || 0,
        },
        greatGrandDame: {
          ...horseData.dame.grandDame.greatGrandDame,
          name: responseData?.dame?.name || "",
          horseId: responseData?.dame.horseId || 0,
        },
      };
      break;

    default:
      console.warn("Heading not found:", heading);
  }
  return updatedHorseData;
};

export const transformPedigreeTreeData = (data) => {
  const transformNode = (node) => {
    if (!node) return null;

    const transformed = {
      horseId: node.horseId || null,
      name: node.name || "",
    };

    // Process nested levels if they exist
    if (node.grandSire) {
      transformed.grandSire = node.grandSire?.name
        ? {
            horseId: node.grandSire.horseId || null,
            name: node.grandSire.name || "",
            greatGrandSire: node.grandSire.greatGrandSire?.name || "",
            greatGrandDame: node.grandSire.greatGrandDame?.name || "",
          }
        : null;
    }

    if (node.grandDame) {
      transformed.grandDame = node.grandDame?.name
        ? {
            horseId: node.grandDame.horseId || null,
            name: node.grandDame.name || "",
            greatGrandSire: node.grandDame.greatGrandSire?.name || "",
            greatGrandDame: node.grandDame.greatGrandDame?.name || "",
          }
        : null;
    }

    return transformed;
  };

  // Transform the root-level data
  return {
    id: data.id || 0,
    horseId: data.horseId || null,
    name: data.name || "",
    sire: data.sire?.name ? transformNode(data.sire) : null,
    dame: data.dame?.name ? transformNode(data.dame) : null,
  };
};


