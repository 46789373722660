import React, { useState, useEffect } from "react";
import { Box, Button, DialogActions } from "@mui/material";
import HorseTree from "./HorseTree";
import endpoints from "../../../constants/endpoints";
import {
  horsePedigreeTreeData,
  transformPedigreeTreeData,
  updateHorseDataWithNames,
} from "../../../helpers/util";
import { useTranslation } from "react-i18next";
import { PageLoading } from "../../../components/icons/PageLoading";
import { HorseService } from "../../../services/HorseServices";
import ToastConfig from "../../../components/toast/toast";
import { LoadingButton } from "@mui/lab";

const PedigreeDetails = ({ horseName, horseId, setOpenPedigree }) => {
  const [horseData, setHorseData] = useState(
    horsePedigreeTreeData(horseName, horseId)
  );
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const fetchInitialPedigree = async () => {
    setIsLoading(true);
    try {
      const query = `?id=${horseId}&term=${horseName}&currentLevel=${0}`;
      const response = await fetch(
        `${endpoints.HORSES.GET_PEDIGREE_BY_LEVEL_SEARCH}${query}`
      );
      const data = await response.json();
      if (data.success) {
        const updatedHorseData = updateHorseDataWithNames(data.data, horseData);
        setHorseData(updatedHorseData);
        setIsLoading(false);
      } else {
        console.error("Error fetching initial pedigree data:", data.message);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching initial pedigree data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchInitialPedigree();
  }, [horseId]);

  const handleSave = () => {
    setIsLoadingUpdate(true);
    const data = transformPedigreeTreeData({ ...horseData });
    HorseService.saveFamilyTree(data).then((response) => {
      if (response.success) {
        ToastConfig.success("Pedigree details successfully updated");
        setOpenPedigree(false);
        setIsLoadingUpdate(false);
      } else {
        setIsLoadingUpdate(false);
        var responceStatusCode = response.statusCode;
        ToastConfig.error(t(responceStatusCode));
      }
    });
  };

  const handleClose = () => {
    setOpenPedigree(false);
  };

  return (
    <Box>
      {!isLoading ? (
        <>
          <HorseTree
            horseData={horseData}
            horse={horseData}
            setHorseData={setHorseData}
          />

          <DialogActions sx={{ mt: 10 }}>
            <Button
              sx={{
                bgcolor: "#F8F8FA",
                color: "primary.main",
                textTransform: "capitalize",
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <LoadingButton
              sx={{
                bgcolor: "primary.main",
                textTransform: "capitalize",
                color: "#fff",
                ":hover": {
                  bgcolor: "primary.dark",
                  color: "white",
                },
                "& .MuiLoadingButton-loadingIndicator": {
                  color: "white",
                },
              }}
              onClick={handleSave}
              loading={isLoadingUpdate}
            >
              Save
            </LoadingButton>
          </DialogActions>
        </>
      ) : (
        <PageLoading />
      )}
    </Box>
  );
};

export default PedigreeDetails;
