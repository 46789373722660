import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  Button,
  Card,
  CardContent,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
  Checkbox,
  Select,
  MenuItem,
  FormHelperText,
  FormControl,
  OutlinedInput,
  useMediaQuery,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  IconButton,
  Box,
  CircularProgress,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import * as yup from "yup";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import DevAutoCompleteUser from "../../components/DevAutoCompleteUser";
import MuiTextField from "../../components/MuiTextField";
import ToastConfig from "../../components/toast/toast";
import { calculate_age_by_year, dateFormat } from "../../helpers/util";
import { HorseService } from "../../services/HorseServices";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import paths from "../../routing/paths";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { PageLoading } from "../../components/icons/PageLoading";
import PedigreeDetails from "./PedigreeDetails/PedigreeDetails";
import { DownloadIcon } from "../../components/icons/DownloadIcon";
import endpoints from "../../constants/endpoints";

const horseSex = ["Stallion", "Mare", "Gelding", "Filly", "Colt"];
const horseColor = [
  "Bay",
  "Dark Bay",
  "Brown",
  "Black",
  "Roan",
  "Piebald",
  "Skewbald",
  "Grey",
  "Chestnut",
  "Piebald",
];
const horseGait = ["Trotter", "Pacer"];
const handicapOption = [
  "Grade G",
  "Grade G1 (won Grade G Race)",

  "Grade G2",

  "Grade F (won a G1 Race in less than six runs or won a G2 race)",

  "Grade E",

  "Grade D",

  "Grade C",

  "Grade B",

  "Grade A",

  "Grade A1",

  "Grade A2",

  "Grade A3",

  "Grade A4",

  "Grade A5 (won a Grade A4 Race)",

  "Grade A6",
];
const handicapValidation =
  process.env.REACT_APP_ISO === "IE" ? "[a-zA-Z0-9]" : "[a-zA-Z0-9]";
const handicapLable = process.env.REACT_APP_ISO === "IE" ? "Grade" : "Handicap";
const useStyles = makeStyles((theme) => ({
  section: {
    "& .fullWidth": {
      maxWidth: "100% !important",
      minWidth: "100%",
      width: "100% !important",
    },
  },
}));
const HorseDetails = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openPedigree, setOpenPedigree] = React.useState(false);
  const [dateVaccine, setDateVaccine] = React.useState(new Date());
  const [dateValue, setDateValue] = React.useState("");
  const [isLoadingSubmit, setIsHandleSubmit] = useState(false);

  const [horse, setHorse] = useState(null);
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();
  const matches = useMediaQuery(
    (_theme) => _theme?.breakpoints?.down("sm") ?? "600"
  );

  const handelAdd = (value, e, setFieldValue, dateCurrentValue) => {
    setFieldValue(value, e);
    if (e) {
      setOpenDialog(true);
      setDateValue(dateCurrentValue);
    } else {
      setFieldValue(dateCurrentValue, "");
    }
  };
  const submitDate = (setFieldValue) => {
    setFieldValue(dateValue, dateVaccine ? new Date(dateVaccine) : "");
    setDateValue("");
    setOpenDialog(false);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const cancelFn = () => {
    history.push(paths.HORSE_LIST);
    props.handleClose();
  };

  const handlePedigreeClose = () => {
    setOpenPedigree(false);
  };

  const handlePedigreeOpen = () => {
    setOpenPedigree(true);
  };

  const handleDownloadPassportPdf = async () => {
    setIsHandleSubmit(true);
    let data = {
      // horseName: values.name,
      id: values?.id,
    };
    var myHeaders = new Headers();
    myHeaders.append("Content-type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: "follow",
    };
    await fetch(endpoints.HORSES.DOWNLOAD_PASSPORT, requestOptions)
      .then((response) => {
        const contentDisposition = response.headers.get("Content-Disposition");
        let filename = "passport.pdf"; // Default filename
         if (contentDisposition) {       
            const match = contentDisposition.match(/filename="?(.+?)"?$/); 
            if (match) { filename = match[1]; } }

        return response.blob().then((blob) => ({ blob, filename }));
      })
      .then(({blob, filename,}) => {
        console.log(filename,)
        setIsHandleSubmit(false);
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        let dateTime = new Date();
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  };

  useEffect(() => {
    const loadData = (id) => {
      setIsLoading(true);
      HorseService.getById(id).then((response) => {
        if (response.success) {
          setHorse(response.data);
        } else {
          props.id !== 0 && ToastConfig.error("Could Not get hourse Details");
        }
        setIsLoading(false);
      });
    };
    loadData(props.id);
    return () => {};
  }, [props.id]);

  useEffect(() => {
    //TODO validation
    if (horse != null) {
      let owners = horse?.allOwners ? horse?.allOwners : [];
      let driver = horse?.driver ? horse?.driver : {};
      let trainer = horse?.trainer ? horse?.trainer : {};
      let breeder = horse?.breeder ? horse?.breeder : {};
      let vaccine = horse?.vaccine;

      setFieldValue("id", horse?.id ? horse?.id : 0);
      setFieldValue("name", horse?.name ? horse?.name : "");

      setFieldValue(
        "dateOfBirth",
        horse?.dateOfBirth ? horse?.dateOfBirth : new Date()
      );
      setFieldValue(
        "age",
        horse?.dateOfBirth ? calculate_age_by_year(horse?.dateOfBirth) : 0
      );

      setFieldValue(
        "microchipNumber",
        horse?.microchipNumber ? horse?.microchipNumber : ""
      );
      setFieldValue("ueln", horse?.ueln ? horse?.ueln : "");
      setFieldValue(
        "passportNumber",
        horse?.passportNumber ? horse?.passportNumber : ""
      );

      setFieldValue(
        "saddleTrotLicence",
        horse?.saddleTrotLicence ? horse?.saddleTrotLicence : ""
      );
      setFieldValue("gait", horse?.gait ? horse?.gait : "");
      setFieldValue("handicap", horse?.handicap ? horse?.handicap : "");
      setFieldValue("sex", horse?.sex ? horse?.sex : "");
      setFieldValue("color", horse?.color ? horse?.color : "");
      setFieldValue(
        "description",
        horse?.description ? horse?.description : ""
      );

      horse?.vaccine && setFieldValue("vaccine", horse?.vaccine);
      if (vaccine) {
        setFieldValue("vaccine.id", vaccine.id);
        setFieldValue(
          "vaccine.equineFluFirstPrimary",
          vaccine.equineFluFirstPrimary
        );
        setFieldValue(
          "vaccine.equineFluFirstPrimaryDateUTC",
          vaccine.equineFluFirstPrimary
            ? vaccine.equineFluFirstPrimaryDateUTC
            : ""
        );
        setFieldValue(
          "vaccine.equineAnnualBooster",
          vaccine.equineAnnualBooster
        );
        setFieldValue(
          "vaccine.equineAnnualBoosterDateUTC",
          vaccine.equineAnnualBoosterDateUTC
        );
        setFieldValue("vaccine.equineFirstBooster", vaccine.equineFirstBooster);
        setFieldValue(
          "vaccine.equineFirstBoosterDateUTC",
          vaccine.equineFirstBoosterDateUTC
        );
        setFieldValue(
          "vaccine.equineFluSecondPrimary",
          vaccine.equineFluSecondPrimary
        );
        setFieldValue(
          "vaccine.equineFluSecondPrimaryDateUTC",
          vaccine.equineFluSecondPrimaryDateUTC
        );
        setFieldValue("vaccine.herperFirstBooster", vaccine.herperFirstBooster);
        setFieldValue(
          "vaccine.herperFirstBoosterDateUTC",
          vaccine.herperFirstBoosterDateUTC
        );
        setFieldValue("vaccine.herperFirstPrimary", vaccine.herperFirstPrimary);
        setFieldValue(
          "vaccine.herperFirstPrimaryDateUTC",
          vaccine.herperFirstPrimaryDateUTC
        );
        setFieldValue(
          "vaccine.herperSecondPrimary",
          vaccine.herperSecondPrimary
        );
        setFieldValue(
          "vaccine.herperSecondPrimaryDateUTC",
          vaccine.herperSecondPrimaryDateUTC
        );
      }
      setFieldValue("pedigree", horse?.pedigree ? horse?.pedigree : { id: 0 });
      setFieldValue("owners", owners);

      setFieldValue("driver", driver);
      setFieldValue("trainer", trainer);
      setFieldValue("breeder", breeder);
      setFieldValue(
        "horseInTraining",
        horse?.horseInTraining ? horse?.horseInTraining : false
      );
      setFieldValue(
        "totalShareHolding",
        owners.reduce((a, b) => +a + +b.procent, 0)
      );
    }
  }, [horse]);
  const validationSchema = yup.object({
    name: yup.string("Enter Horse name").required("Horse name is required"),
    microchipNumber: yup
      .string("Enter Microchip number")
      .required("Microchip number is required"),
    gait: yup.string("Enter Gait").required("Gait value is required"),
    ueln: yup.string("Enter UELN").required("UELN value is required"),
    handicap: yup
      .string("Enter Handicap")
      .required("Handicap value is required")
      .matches(handicapValidation, "Enter Valid Handicap"),
    sex: yup.string("Enter sex").required("Sex value is required"),
    color: yup.string("Enter color").required("Color  is required"),
    totalShareHolding: yup
      .number()
      .max(100, "Need to be 100 %")
      .min(100, "Need to be 100 %")
      .required("Need to be 100%"),
    horseInTraining: yup.boolean(),
  });

  const {
    handleSubmit,
    handleBlur,
    handleChange,
    touched,
    values,
    errors,
    setFieldValue,
    validateField,
  } = useFormik({
    initialValues: {
      id: 0,
      name: "",
      microchipNumber: "",
      gait: "",
      ueln: "",
      handicap: "",
      sex: "",
      color: "",
      passportNumber: "",
      vaccine: {
        id: 0,
        equineFluFirstPrimary: false,
        equineFluFirstPrimaryDateUTC: "",

        equineAnnualBooster: false,
        equineAnnualBoosterDateUTC: "",

        equineFirstBooster: false,
        equineFirstBoosterDateUTC: "",

        equineFluSecondPrimary: false,
        equineFluSecondPrimaryDateUTC: "",

        herperFirstBooster: false,
        herperFirstBoosterDateUTC: "",

        herperFirstPrimary: false,
        herperFirstPrimaryDateUTC: "",

        herperSecondPrimary: false,
        herperSecondPrimaryDateUTC: "",
      },
      totalShareHolding: 0,
      owners: [],
      pedigree: { id: 0 },
      driver: { id: 0 },
      trainer: { id: 0 },
      breeder: { id: 0 },
      horseInTraining: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let data = values;
      let AllOwners = values.owners;
      data.owners = [];
      data.syndicates = AllOwners.filter((i) => i.type === "Syndicate");
      data.owners = AllOwners.filter((i) => i.type === "User");
      HorseService.save(data).then((response) => {
        if (response.success) {
          ToastConfig.success("Horse successfully saved");
          history.push(paths.HORSE_LIST);
          props.handleClose();
        } else {
          var responceStatusCode = response.statusCode;
          ToastConfig.error(t(responceStatusCode));
          values.owners = AllOwners;
        }
      });
    },
  });

  return (
    <>
      {!isLoading ? (
        <>
          <form onSubmit={handleSubmit} id="UserAddEditForm">
            <Grid
              container
              spacing={2}
              className={matches ? classes.section : " "}
            >
              <Grid container item xs={12} sx={{ display: "inline-block" }}>
                <Card
                  sx={{
                    background: "#FFFFFF",
                    borderRadius: "15px",
                    boxShadow: "none",
                  }}
                >
                  <CardContent>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Grid container spacing={2}>
                        <Grid
                          container
                          item
                          xs={12}
                          alignItems="center"
                          display="flex"
                          gap={2}
                        >
                          <Typography>
                            <b>HORSE DETAILS</b>
                          </Typography>
                          {process.env.REACT_APP_ISO === "IE" &&
                            values?.id > 0 && (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={handlePedigreeOpen}
                              >
                                View Pedigree
                              </Button>
                            )}
                        </Grid>
                        {process.env.REACT_APP_ISO === "IE" && (
                          <Dialog
                            open={openPedigree}
                            onClose={handlePedigreeClose}
                            // maxWidth="lg"
                            fullScreen
                          >
                            <DialogActions
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography variant="h6" gutterBottom>
                                Pedigree Details
                              </Typography>

                              <Box display={"flex"} alignItems={"center"}>
                                <Grid
                                  container
                                  item
                                  xs={12}
                                  alignItems="center"
                                >
                                  <Typography mt="7px">
                                    Download Passport Pdf
                                  </Typography>

                                  {!isLoadingSubmit ? (
                                    <IconButton
                                      onClick={handleDownloadPassportPdf}
                                    >
                                      <DownloadIcon />
                                    </IconButton>
                                  ) : (
                                    <IconButton>
                                      <CircularProgress size={"12px"} />
                                    </IconButton>
                                  )}
                                </Grid>

                                <Typography onClick={handlePedigreeClose}>
                                  <IconButton>
                                    <CloseIcon />
                                  </IconButton>
                                </Typography>
                              </Box>
                            </DialogActions>

                            <DialogContent>
                              <PedigreeDetails
                                horseName={values.name}
                                horseId={values?.id}
                                setOpenPedigree={setOpenPedigree}
                              />
                            </DialogContent>
                          </Dialog>
                        )}

                        <Grid item xs={3} className="fullWidth">
                          <Typography>{"Horse name*"}</Typography>
                          <MuiTextField
                            id="name"
                            placeholder={"ARTHURIAN FAME"}
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.name && Boolean(errors.name)}
                            helperText={touched.name && errors.name}
                          />
                        </Grid>
                        <Grid item xs={2} className="fullWidth">
                          <Typography>{"Date of Birth *"}</Typography>
                          <DatePicker
                            name="dateOfBirth"
                            id="dateOfBirth"
                            inputFormat="dd/MM/yyyy"
                            openTo="day"
                            views={["day", "month", "year"]}
                            maxDate={new Date()}
                            value={values.dateOfBirth}
                            onChange={(newValue) => {
                              setFieldValue("dateOfBirth", newValue);
                              setFieldValue(
                                "age",
                                calculate_age_by_year(newValue)
                              );
                            }}
                            renderInput={(params) => (
                              <MuiTextField
                                {...params}
                                error={
                                  touched.dateOfBirth &&
                                  Boolean(errors.dateOfBirth)
                                }
                                helperText={
                                  touched.dateOfBirth && errors.dateOfBirth
                                }
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={1} className="fullWidth">
                          <Typography>{"Age"}</Typography>
                          <MuiTextField
                            id="age"
                            name="age"
                            disabled={true}
                            placeholder="0"
                            value={values.age}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={touched.age && Boolean(errors.age)}
                            helperText={touched.age && errors.age}
                          />
                        </Grid>
                        <Grid item xs={2} className="fullWidth">
                          <Typography>{"Color*"}</Typography>
                          <FormControl sx={{ width: "100%" }}>
                            <Select
                              autoWidth={true}
                              displayEmpty
                              size="small"
                              id="color"
                              value={values.color}
                              onChange={(e) => {
                                setFieldValue("color", e.target.value);
                              }}
                              input={<OutlinedInput />}
                              renderValue={(selected) => {
                                return selected ? selected : "Please Select";
                              }}
                              inputProps={{ "aria-label": "Without label" }}
                            >
                              <MenuItem value="">
                                <em>Please Select</em>
                              </MenuItem>
                              {horseColor.map((item, i) => (
                                <MenuItem key={i} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormHelperText
                            error={true}
                            style={{
                              display: "inline-block",
                              float: "right",
                            }}
                          >
                            {errors.color && touched.color && errors.color}
                          </FormHelperText>
                        </Grid>

                        <Grid container item xs={12} spacing={2}>
                          <Grid item xs={3} className="fullWidth">
                            <Typography>{"Microchip number*"}</Typography>
                            <MuiTextField
                              id="microchipNumber"
                              placeholder={"234124888785WQAQKK"}
                              value={values.microchipNumber}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                touched.microchipNumber &&
                                Boolean(errors.microchipNumber)
                              }
                              helperText={
                                touched.microchipNumber &&
                                errors.microchipNumber
                              }
                            />
                          </Grid>
                          <Grid item xs={2} className="fullWidth">
                            <Typography>{"UELN *"}</Typography>
                            <MuiTextField
                              id="ueln"
                              placeholder={"1212120078M0234"}
                              value={values.ueln}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.ueln && Boolean(errors.ueln)}
                              helperText={touched.ueln && errors.ueln}
                            />
                          </Grid>
                          <Grid item xs={3} className="fullWidth">
                            <Typography>{"Passport No"}</Typography>
                            <MuiTextField
                              id="passportNumber"
                              placeholder={"78665988445875"}
                              value={values.passportNumber}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                touched.passportNumber &&
                                Boolean(errors.passportNumber)
                              }
                              helperText={
                                touched.passportNumber && errors.passportNumber
                              }
                            />
                          </Grid>
                          <Grid item xs={2}></Grid>
                        </Grid>
                        <Grid container item xs={12} spacing={2}>
                          <Grid item xs={3} className="fullWidth">
                            <Typography>{"Gait*"}</Typography>
                            <FormControl sx={{ width: "90%" }}>
                              <Select
                                autoWidth={true}
                                displayEmpty
                                size="small"
                                id="gait"
                                value={values.gait}
                                onChange={(e) => {
                                  setFieldValue("gait", e.target.value);
                                }}
                                input={<OutlinedInput />}
                                renderValue={(selected) => {
                                  return selected ? selected : "Please Select";
                                }}
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                <MenuItem value="">
                                  <em>Please Select</em>
                                </MenuItem>
                                {horseGait.map((item, i) => (
                                  <MenuItem key={i} value={item}>
                                    {item}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <FormHelperText
                              error={true}
                              style={{
                                display: "inline-block",
                                float: "right",
                              }}
                            >
                              {errors.gait && touched.gait && errors.gait}
                            </FormHelperText>
                          </Grid>

                          <Grid item xs={2} className="fullWidth">
                            <Typography>{handicapLable}*</Typography>
                            {process.env.REACT_APP_ISO === "IE" ? (
                              <>
                                <FormControl sx={{ width: "100%" }}>
                                  <Select
                                    autoWidth={true}
                                    displayEmpty
                                    size="small"
                                    id="handicap"
                                    value={values.handicap}
                                    onChange={(e) => {
                                      setFieldValue("handicap", e.target.value);
                                    }}
                                    input={<OutlinedInput />}
                                    renderValue={(selected) => {
                                      return selected
                                        ? selected
                                        : "Please Select";
                                    }}
                                    inputProps={{
                                      "aria-label": "Without label",
                                    }}
                                  >
                                    <MenuItem value="">
                                      <em>Please Select</em>
                                    </MenuItem>
                                    {handicapOption.map((item, i) => (
                                      <MenuItem key={i} value={item}>
                                        {item}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                                <FormHelperText
                                  error={true}
                                  style={{
                                    display: "inline-block",
                                    float: "right",
                                  }}
                                >
                                  {errors.handicap &&
                                    touched.handicap &&
                                    errors.handicap}
                                </FormHelperText>
                              </>
                            ) : (
                              <MuiTextField
                                id="handicap"
                                placeholder={"Trotter"}
                                value={values.handicap}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  touched.handicap && Boolean(errors.handicap)
                                }
                                helperText={touched.handicap && errors.handicap}
                              />
                            )}
                          </Grid>
                          <Grid item xs={2} className="fullWidth">
                            <Typography>{"Sex*"}</Typography>
                            <FormControl sx={{ width: "100%" }}>
                              <Select
                                autoWidth={true}
                                displayEmpty
                                size="small"
                                id="sex"
                                value={values.sex}
                                onChange={(e) => {
                                  setFieldValue("sex", e.target.value);
                                }}
                                input={<OutlinedInput />}
                                renderValue={(selected) => {
                                  return selected ? selected : "Please Select";
                                }}
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                <MenuItem value="">
                                  <em>Please Select</em>
                                </MenuItem>
                                {horseSex.map((item, i) => (
                                  <MenuItem key={i} value={item}>
                                    {item}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <FormHelperText
                              error={true}
                              style={{
                                display: "inline-block",
                                float: "right",
                              }}
                            >
                              {errors.sex && touched.sex && errors.sex}
                            </FormHelperText>
                          </Grid>
                        </Grid>
                        <Grid container item xs={12} spacing={2}>
                          <Grid item xs={2} className="fullWidth">
                            <Typography>{"Trainer"}</Typography>
                            <DevAutoCompleteUser
                              role="trainer"
                              updateformik={setFieldValue}
                              placeholder="Please start typing"
                              trainer={values.trainer}
                              validateField={validateField}
                              width={"196px"}
                            />
                            <FormHelperText
                              error={true}
                              style={{
                                display: "inline-block",
                                float: "right",
                              }}
                            >
                              {errors.trainer?.id && errors.trainer?.id}
                            </FormHelperText>
                          </Grid>
                          <Grid item xs={2} className="fullWidth">
                            <Typography>{"Driver"}</Typography>
                            <DevAutoCompleteUser
                              role="driver"
                              updateformik={setFieldValue}
                              placeholder="Please start typing"
                              driver={values.driver}
                              validateField={validateField}
                              width={"196px"}
                            />
                            <FormHelperText
                              error={true}
                              style={{
                                display: "inline-block",
                                float: "right",
                              }}
                            >
                              {errors.driver?.id && errors.driver?.id}
                            </FormHelperText>
                          </Grid>
                          <Grid item xs={2} className="fullWidth">
                            <Typography>{"Breeder"}</Typography>
                            <DevAutoCompleteUser
                              role="breeder"
                              updateformik={setFieldValue}
                              placeholder="Please start typing"
                              breeder={values.breeder}
                              validateField={validateField}
                              width={"196px"}
                            />
                            <FormHelperText
                              error={true}
                              style={{
                                display: "inline-block",
                                float: "right",
                              }}
                            >
                              {errors.breeder?.id && errors.breeder?.id}
                            </FormHelperText>
                          </Grid>

                          <Grid item xs={4} className="fullWidth">
                            <FormControlLabel
                              sx={{ mt: 3 }}
                              control={
                                <Checkbox
                                  checked={values.horseInTraining}
                                  onChange={handleChange}
                                  name="horseInTraining"
                                  color="primary"
                                />
                              }
                              label="Horse in Training"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </LocalizationProvider>
                  </CardContent>
                </Card>
              </Grid>

              {/* date modal start  */}
              <Dialog
                maxWidth={"lg"}
                open={openDialog}
                onClose={handleDialogClose}
                PaperProps={{ sx: { minWidth: matches ? "100%" : "" } }}
              >
                <DialogContent sx={{ maxWidth: "590px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="h5" sx={{ pb: 3 }}>
                        {"Add Date"}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        style={{ float: "right" }}
                        onClick={handleDialogClose}
                      >
                        <IconButton>
                          <CloseIcon />
                        </IconButton>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} className="fullWidth">
                      <Typography>{"Date of Vaccine"}</Typography>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          name="dateVaccine"
                          id="dateVaccine"
                          disableFuture
                          inputFormat="dd/MM/yyyy"
                          openTo="year"
                          views={["year", "month", "day"]}
                          value={dateVaccine}
                          onChange={(newValue) => {
                            setDateVaccine(newValue);
                          }}
                          renderInput={(params) => <MuiTextField {...params} />}
                        />
                      </LocalizationProvider>
                    </Grid>

                    <Grid
                      item
                      container
                      justifyContent={"end"}
                      alignContent={"center"}
                      xs={12}
                    >
                      <Button
                        sx={{
                          bgcolor: "primary.main",
                          minWidth: "170px",
                          textTransform: "capitalize",
                          color: "#fff",
                          float: "right",
                          marginTop: "30px",
                          ":hover": {
                            bgcolor: "primary.dark",
                            color: "white",
                          },
                        }}
                        type="submit"
                        onClick={() => submitDate(setFieldValue)}
                      >
                        Set
                      </Button>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions></DialogActions>
              </Dialog>

              {/* date modal end  */}
              <Grid container item xs={12} sx={{ display: "inline-block" }}>
                <Card
                  sx={{
                    background: "#FFFFFF",
                    borderRadius: "15px",
                    boxShadow: "none",
                  }}
                >
                  <CardContent>
                    <Grid container spacing={1}>
                      <Grid container item xs={12}>
                        <Typography>
                          <b> VACCINES</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>Equine Flu Vaccine</Typography>
                      </Grid>
                      <Grid
                        item
                        justifyContent="flex-start"
                        direction={"row"}
                        alignItems="center"
                        xs={12}
                      >
                        <Grid item md={12} spacing={2}>
                          <Grid container spacing={2}>
                            <Grid item xs={2} className="fullWidth">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={
                                      values.vaccine?.equineFluFirstPrimary
                                    }
                                  />
                                }
                                name="vaccine.equineFluFirstPrimary"
                                value={values.vaccine?.equineFluFirstPrimary}
                                onChange={(e) => {
                                  handelAdd(
                                    "vaccine.equineFluFirstPrimary",
                                    e.target.checked,
                                    setFieldValue,
                                    "vaccine.equineFluFirstPrimaryDateUTC"
                                  );
                                }}
                                labelPlacement={matches ? "end" : " start"}
                                label="First Primary"
                                sx={{
                                  color: "#979797",
                                  marginLeft: "0px",
                                  marginRight: "0px",
                                }}
                              />
                              <Typography>
                                {values.vaccine.equineFluFirstPrimaryDateUTC
                                  ? dateFormat(
                                      values.vaccine
                                        .equineFluFirstPrimaryDateUTC
                                    )
                                  : ""}{" "}
                              </Typography>
                            </Grid>

                            <Grid item xs={2} className="fullWidth">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={
                                      values.vaccine?.equineFluSecondPrimary
                                    }
                                  />
                                }
                                name="vaccine.equineFluSecondPrimary"
                                value={values.vaccine?.equineFluSecondPrimary}
                                onChange={(e) => {
                                  handelAdd(
                                    "vaccine.equineFluSecondPrimary",
                                    e.target.checked,
                                    setFieldValue,
                                    "vaccine.equineFluSecondPrimaryDateUTC"
                                  );
                                }}
                                labelPlacement={matches ? "end" : " start"}
                                label="Second Primary"
                                sx={{
                                  color: "#979797",
                                  marginLeft: "0px",
                                  marginRight: "0px",
                                }}
                              />
                              <Typography>
                                {values.vaccine.equineFluSecondPrimaryDateUTC
                                  ? dateFormat(
                                      values.vaccine
                                        .equineFluSecondPrimaryDateUTC
                                    )
                                  : ""}
                              </Typography>
                            </Grid>

                            <Grid item xs={2} className="fullWidth">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={values.vaccine?.equineFirstBooster}
                                  />
                                }
                                name="vaccine.equineFirstBooster"
                                value={values.vaccine?.equineFirstBooster}
                                onChange={(e) => {
                                  handelAdd(
                                    "vaccine.equineFirstBooster",
                                    e.target.checked,
                                    setFieldValue,
                                    "vaccine.equineFirstBoosterDateUTC"
                                  );
                                }}
                                labelPlacement={matches ? "end" : " start"}
                                label="First booster"
                                sx={{
                                  color: "#979797",
                                  marginLeft: "0px",
                                  marginRight: "0px",
                                }}
                              />

                              <Typography>
                                {values.vaccine.equineFirstBoosterDateUTC
                                  ? dateFormat(
                                      values.vaccine.equineFirstBoosterDateUTC
                                    )
                                  : ""}
                              </Typography>
                            </Grid>

                            <Grid item xs={2} className="fullWidth">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={
                                      values.vaccine?.equineAnnualBooster
                                    }
                                  />
                                }
                                name="vaccine.equineAnnualBooster"
                                value={values.vaccine?.equineAnnualBooster}
                                onChange={(e) => {
                                  handelAdd(
                                    "vaccine.equineAnnualBooster",
                                    e.target.checked,
                                    setFieldValue,
                                    "vaccine.equineAnnualBoosterDateUTC"
                                  );
                                }}
                                labelPlacement={matches ? "end" : " start"}
                                label="Annual booster"
                                sx={{
                                  color: "#979797",
                                  marginLeft: "0px",
                                  marginRight: "0px",
                                }}
                              />
                              <Typography>
                                {values.vaccine.equineAnnualBoosterDateUTC
                                  ? dateFormat(
                                      values.vaccine.equineAnnualBoosterDateUTC
                                    )
                                  : ""}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography>Herpes Virus</Typography>
                      </Grid>
                      <Grid
                        item
                        container
                        justifyContent="flex-start"
                        direction={"row"}
                        alignItems="center"
                        xs={12}
                      >
                        <Grid item xs={12}>
                          <Grid item md={12} spacing={2}>
                            <Grid container spacing={2}>
                              <Grid item xs={2} className="fullWidth">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      checked={
                                        values.vaccine?.herperFirstPrimary
                                      }
                                    />
                                  }
                                  name="vaccine.herperFirstPrimary"
                                  value={values.vaccine?.herperFirstPrimary}
                                  onChange={(e) => {
                                    handelAdd(
                                      "vaccine.herperFirstPrimary",
                                      e.target.checked,
                                      setFieldValue,
                                      "vaccine.herperFirstPrimaryDateUTC"
                                    );
                                  }}
                                  labelPlacement={matches ? "end" : " start"}
                                  label="First Primary"
                                  sx={{
                                    color: "#979797",
                                    marginLeft: "0px",
                                    marginRight: "0px",
                                  }}
                                />
                                <Typography>
                                  {values.vaccine.herperFirstPrimaryDateUTC
                                    ? dateFormat(
                                        values.vaccine.herperFirstPrimaryDateUTC
                                      )
                                    : ""}
                                </Typography>
                              </Grid>

                              <Grid item xs={2} className="fullWidth">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      checked={
                                        values.vaccine?.herperSecondPrimary
                                      }
                                    />
                                  }
                                  name="vaccine.herperSecondPrimary"
                                  value={values.vaccine?.herperSecondPrimary}
                                  onChange={(e) => {
                                    handelAdd(
                                      "vaccine.herperSecondPrimary",
                                      e.target.checked,
                                      setFieldValue,
                                      "vaccine.herperSecondPrimaryDateUTC"
                                    );
                                  }}
                                  labelPlacement={matches ? "end" : " start"}
                                  label="Second Primary"
                                  sx={{
                                    color: "#979797",
                                    marginLeft: "0px",
                                    marginRight: "0px",
                                  }}
                                />
                                <Typography>
                                  {values.vaccine.herperSecondPrimaryDateUTC
                                    ? dateFormat(
                                        values.vaccine
                                          .herperSecondPrimaryDateUTC
                                      )
                                    : ""}
                                </Typography>
                              </Grid>

                              <Grid item xs={2} className="fullWidth">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      checked={
                                        values.vaccine?.herperFirstBooster
                                      }
                                    />
                                  }
                                  name="vaccine.herperFirstBooster"
                                  value={values.vaccine?.herperFirstBooster}
                                  onChange={(e) => {
                                    handelAdd(
                                      "vaccine.herperFirstBooster",
                                      e.target.checked,
                                      setFieldValue,
                                      "vaccine.herperFirstBoosterDateUTC"
                                    );
                                  }}
                                  labelPlacement={matches ? "end" : " start"}
                                  label="First booster"
                                  sx={{
                                    color: "#979797",
                                    marginLeft: "0px",
                                    marginRight: "0px",
                                  }}
                                />
                                <Typography>
                                  {values.vaccine.herperFirstBoosterDateUTC
                                    ? dateFormat(
                                        values.vaccine.herperFirstBoosterDateUTC
                                      )
                                    : ""}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid container item xs={12} sx={{ display: "inline-block" }}>
                <Card
                  sx={{
                    background: "#FFFFFF",
                    borderRadius: "15px",
                    boxShadow: "none",
                  }}
                >
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid container item xs={12} spacing={2}>
                        <Grid item>
                          <Typography>
                            <b> OWNERSHIP*</b>
                          </Typography>
                        </Grid>
                        <Grid item>
                          <FormHelperText
                            error={true}
                            style={{ display: "inline-block" }}
                          >
                            {errors.totalShareHolding &&
                              errors.totalShareHolding}
                          </FormHelperText>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        justifyItems={"center"}
                        alignItems={"flex-start"}
                        spacing={2}
                        className="fullWidth"
                      >
                        <DevAutoCompleteUser
                          role="owner"
                          updateformik={setFieldValue}
                          placeholder="Please start typing"
                          owners={values.owners}
                          source={3}
                          ownership={"ownership"}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid container item xs={12} sx={{ display: "inline-block" }}>
                <Card
                  sx={{
                    background: "#FFFFFF",
                    borderRadius: "15px",
                    boxShadow: "none",
                  }}
                >
                  <CardContent>
                    {process.env.REACT_APP_ISO !== "IE" && (
                      <>
                        <Grid container spacing={2}>
                          <Grid container item xs={12}>
                            <Typography>
                              <b> PEDIGREE DETAILS</b>
                            </Typography>
                          </Grid>
                          <Grid item container xs={12} spacing={2}>
                            <Grid item xs={3} className="fullWidth">
                              <MuiTextField
                                name="pedigree.sireName"
                                placeholder="Sire Name"
                                value={values.pedigree?.sireName}
                                onChange={handleChange}
                                error={
                                  touched.pedigree?.sireName &&
                                  Boolean(errors.pedigree?.sireName)
                                }
                                helperText={
                                  touched.pedigree?.sireName &&
                                  errors.pedigree?.sireName
                                }
                              />
                            </Grid>
                            <Grid item xs={3} className="fullWidth">
                              <MuiTextField
                                name="pedigree.dameName"
                                placeholder="Dam Name"
                                value={values.pedigree?.dameName}
                                onChange={handleChange}
                                error={
                                  touched.pedigree?.dameName &&
                                  Boolean(errors.pedigree?.dameName)
                                }
                                helperText={
                                  touched.pedigree?.dameName &&
                                  errors.pedigree?.dameName
                                }
                              />
                            </Grid>
                            <Grid item xs={3} className="fullWidth">
                              <MuiTextField
                                name="pedigree.dameSIreName"
                                placeholder="Dam Sire Name"
                                value={values.pedigree?.dameSIreName}
                                onChange={handleChange}
                                error={
                                  touched.pedigree?.dameSIreName &&
                                  Boolean(errors.pedigree?.dameSIreName)
                                }
                                helperText={
                                  touched.pedigree?.dameSIreName &&
                                  errors.pedigree?.dameSIreName
                                }
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ pt: 2, pb: 2 }}>
                          <Divider variant="middle" />
                        </Grid>
                      </>
                    )}
                    <Grid container spacing={2}>
                      <Grid container item xs={12}>
                        <Typography>
                          <b> Description (Optional)</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <MuiTextField
                          name="description"
                          multiline
                          minRows={4}
                          placeholder="Your message"
                          maxRows={4}
                          value={values.description}
                          onChange={handleChange}
                          error={
                            touched.description && Boolean(errors.description)
                          }
                          helperText={touched.description && errors.description}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                xs={12}
                sx={{ pt: 6 }}
              >
                <Grid item sx={{ pr: 2 }}>
                  <Button
                    sx={{
                      bgcolor: "#F8F8FA",
                      color: "primary.main",
                      textTransform: "capitalize",
                    }}
                    onClick={cancelFn}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  {" "}
                  <Button
                    sx={{
                      bgcolor: "primary.main",
                      textTransform: "capitalize",
                      color: "#fff",
                      float: "right",
                      ":hover": {
                        bgcolor: "primary.dark",
                        color: "white",
                      },
                    }}
                    type="submit"
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </>
      ) : (
        <PageLoading />
      )}
    </>
  );
};

export default HorseDetails;
