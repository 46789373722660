export default {
  AUTH: {
    SIGNIN: process.env.REACT_APP_RESTAPI_ENDPOINT + '/Account/Login',
    SIGNUP: process.env.REACT_APP_RESTAPI_ENDPOINT + '/Account/Register'
  },
  USERS: {
    GET: process.env.REACT_APP_RESTAPI_ENDPOINT + '/User/GetPage',
    SAVE: process.env.REACT_APP_RESTAPI_ENDPOINT + '/User/Save',
    GET_BY_ID: process.env.REACT_APP_RESTAPI_ENDPOINT + '/User/GetDetails?id=',
    SET_STATUS: process.env.REACT_APP_RESTAPI_ENDPOINT + '/User/SetStatus', //used for activate, deactivate and delete
    GET_BY_NAME_ROLE:
      process.env.REACT_APP_RESTAPI_ENDPOINT + '/User/GetUsersByRole' //for auto complete
  },
  HORSES: {
    GET: process.env.REACT_APP_RESTAPI_ENDPOINT + '/Horse/GetPage',
    SAVE: process.env.REACT_APP_RESTAPI_ENDPOINT + '/Horse/Save',
    GET_BY_ID: process.env.REACT_APP_RESTAPI_ENDPOINT + '/Horse/GetDetails?id=',
    SET_STATUS: process.env.REACT_APP_RESTAPI_ENDPOINT + '/Horse/SetStatus', //used for activate, deactivate and delete
    GET_OWNER_BY_NAME: process.env.REACT_APP_RESTAPI_ENDPOINT + '/Horse/GetOwnersByName', //get owners
    GET_FAMILY_TREE:process.env.REACT_APP_RESTAPI_ENDPOINT + '/Horse/GetFamilyTree',
    SAVE_FAMILY_TREE:process.env.REACT_APP_RESTAPI_ENDPOINT + '/Horse/SaveFamilyTree',
    DOWNLOAD_PASSPORT:process.env.REACT_APP_RESTAPI_ENDPOINT + '/Horse/HorsePassport',
    PEDIGREE_SEARCH:process.env.REACT_APP_RESTAPI_ENDPOINT + '/Horse/PedigreeSearch',
    GET_PEDIGREE_BY_LEVEL_SEARCH: process.env.REACT_APP_RESTAPI_ENDPOINT + '/Horse/GetPedigreeByLevelSearch',
    PEDIGREE_HORSE_SEARCH: process.env.REACT_APP_RESTAPI_ENDPOINT + '/Horse/PedigreeHorseSearch'
  },
  SYNDICATES: {
    GET: process.env.REACT_APP_RESTAPI_ENDPOINT + '/Syndicate/GetPage',
    SAVE: process.env.REACT_APP_RESTAPI_ENDPOINT + '/Syndicate/Save',
    GET_BY_ID:
      process.env.REACT_APP_RESTAPI_ENDPOINT + '/Syndicate/GetDetails?id=',
    SET_STATUS: process.env.REACT_APP_RESTAPI_ENDPOINT + '/Syndicate/SetStatus' //used for activate, deactivate and delete
  },
  TRACKS: {
    GET: process.env.REACT_APP_RESTAPI_ENDPOINT + '/Track/GetPage',
    SAVE: process.env.REACT_APP_RESTAPI_ENDPOINT + '/Track/Save',
    GET_BY_ID: process.env.REACT_APP_RESTAPI_ENDPOINT + '/Track/GetDetails?id=',
    SET_STATUS: process.env.REACT_APP_RESTAPI_ENDPOINT + '/Track/SetStatus' //used for activate, deactivate and delete
  },
  MEETING: {
    GET: process.env.REACT_APP_RESTAPI_ENDPOINT + '/Meeting/GetPage',
    SAVE: process.env.REACT_APP_RESTAPI_ENDPOINT + '/Meeting/Save',
    GET_BY_ID:
      process.env.REACT_APP_RESTAPI_ENDPOINT + '/Meeting/GetDetails?id=',
    SET_STATUS: process.env.REACT_APP_RESTAPI_ENDPOINT + '/Meeting/SetStatus' //used for activate, deactivate and delete
  },
  RACES: {
    GET: process.env.REACT_APP_RESTAPI_ENDPOINT + '/Race/GetPage',
    SAVE: process.env.REACT_APP_RESTAPI_ENDPOINT + '/Race/Save',
    GET_BY_ID: process.env.REACT_APP_RESTAPI_ENDPOINT + '/Race/GetDetails?id=',
    SET_STATUS: process.env.REACT_APP_RESTAPI_ENDPOINT + '/Race/SetStatus', //used for activate, deactivate and delete
    GET_HORSE_BY_NAME: process.env.REACT_APP_RESTAPI_ENDPOINT + '/Race/GetHorseDetailsByName', //used for activate, deactivate and delete
    SAVE_STEWARDS_COMMENT: process.env.REACT_APP_RESTAPI_ENDPOINT + '/Race/SaveStewardsComment',
    SAVE_RESULTS:process.env.REACT_APP_RESTAPI_ENDPOINT + '/Race/SaveResults',
    WITHDRAW:process.env.REACT_APP_RESTAPI_ENDPOINT + '/Race/Withdraw',
    GET_RACE_CARD:process.env.REACT_APP_RESTAPI_ENDPOINT + '/Race/GetRaceCard?id=',
  },

  UPLOAD: {
    SAVE: process.env.REACT_APP_RESTAPI_ENDPOINT + '/Storage/upload'
  },
  REPORTS: {
    GET_MICROCHIP: process.env.REACT_APP_RESTAPI_ENDPOINT + '/Reports/GetHorsesMicrochipReport',
    GET_HISTORY: process.env.REACT_APP_RESTAPI_ENDPOINT + '/Reports/GetHorsesHistoryReport',
  }
};
