import React from "react";
import { Box } from "@mui/material";
import HorseCard from "./HorseCard";
const _sx = {
  textAlign: "center",
  marginBottom: "8px",
  overflowX: "auto",
  padding: "1px",
  display: "grid",
  "&::-webkit-scrollbar": {
    width: "8px",
    height: "4px",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#f1f1f1",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#888",
    borderRadius: "8px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#555",
    cursor: "pointer",
  },
};
const HorseTree = ({ horseData,horse,  childLabel,setHorseData }) => {
  // Dynamically find nested keys that represent child data
  const childKeys = Object.keys(horse).filter(
    (key) => typeof horse[key] === "object"
  );

  return (
    <Box sx={_sx}>
      <HorseCard
        horse={horse}
        childLabel={childLabel}
        currentLevel={horse.currentLevel}
        setHorseData={setHorseData}
        horseData={horseData}
      />

      {/* Render child nodes dynamically */}
      {childKeys.length > 0 && (
        <Box
          sx={{
            display: "inline-flex",
            justifyContent: "center",
            marginTop: "16px",
          }}
        >
          {childKeys.map((key) => (
            <Box key={key}>
              <HorseTree
                horse={horse[key]}
                childLabel={true}
                currentLevel={horse[key].currentLevel}
                key={key}
                setHorseData={setHorseData}
                horseData={horseData}
              />
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default HorseTree;
