import endpoints from "../constants/endpoints";
import { fetchWrapper } from "../helpers/fetchWrapper";

export const HorseService = {
  getPage,
  getById,
  save,
  delete: _delete,
  getAllOwnersByName,
  saveFamilyTree,
  getFamilyTree,
};

function getById(id) {
  return fetchWrapper.get(endpoints.HORSES.GET_BY_ID + id);
}
function getPage(params) {
  return fetchWrapper.post(endpoints.HORSES.GET, params);
}
function save(params) {
  return fetchWrapper.post(endpoints.HORSES.SAVE, params);
}
function _delete(params) {
  return fetchWrapper.post(endpoints.HORSES.SET_STATUS, params);
}
function getAllOwnersByName(params,callback){
  return fetchWrapper.get(endpoints.HORSES.GET_OWNER_BY_NAME + params).then(response => {
    callback(response)
  });
}

function getFamilyTree(params) {
  return fetchWrapper.post(endpoints.HORSES.GET_FAMILY_TREE, params);
}

function saveFamilyTree(params) {
  return fetchWrapper.post(endpoints.HORSES.SAVE_FAMILY_TREE, params);
}
